exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-ai-solutions-index-js": () => import("./../../../src/pages/ai-solutions/index.js" /* webpackChunkName: "component---src-pages-ai-solutions-index-js" */),
  "component---src-pages-blockchain-solutions-index-js": () => import("./../../../src/pages/blockchain-solutions/index.js" /* webpackChunkName: "component---src-pages-blockchain-solutions-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-design-and-development-index-js": () => import("./../../../src/pages/design-and-development/index.js" /* webpackChunkName: "component---src-pages-design-and-development-index-js" */),
  "component---src-pages-fast-website-index-js": () => import("./../../../src/pages/fast-website/index.js" /* webpackChunkName: "component---src-pages-fast-website-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mvp-development-index-js": () => import("./../../../src/pages/mvp-development/index.js" /* webpackChunkName: "component---src-pages-mvp-development-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-software-development-index-js": () => import("./../../../src/pages/software-development/index.js" /* webpackChunkName: "component---src-pages-software-development-index-js" */),
  "component---src-pages-staff-augmentation-index-js": () => import("./../../../src/pages/staff-augmentation/index.js" /* webpackChunkName: "component---src-pages-staff-augmentation-index-js" */)
}

